@import "./common.scss";

:root {
  --container-width: 1408px;
  --container-padding: 24px;

  // Pallete
  --color-primary: #65aae0;
  --color-primary-d1: #3c8de2;
  --color-primary-l1: #dbf4ff;
  --color-primary-l2: #eefbff;

  --color-accent: #1473e6;

  --color-secondary: #282828;
  --color-secondary-l1: #5f6066;
  --color-secondary-l2: #797a80;
  --color-secondary-l3: #94959c;
  --color-secondary-l4: #b1b2ba;
  --color-secondary-l5: #d7d9e0;
  --color-secondary-l6: #f2f4f7;
  --color-secondary-l7: #ffffff;
  --color-secondary-l8: #88888861;

  --color-negative: #ff6677;
  --color-nutral: #ffa95c;
  --color-positive: #00cc88;

  // BUTTONS
  // Desktop

  // large

  --button-size-l-padding: 5px 22px;
  --button-size-l-radius: 6px;
  --button-size-l-font: normal normal bold 28px/32px PT Sans;
  --button-size-l-lineheight: 32px;
  --button-size-l-text-transform: initial;

  // medium
  --button-size-m-padding: 5px 22px;
  --button-size-m-radius: 6px;
  --button-size-m-font: normal normal bold 18px/32px PT Sans;
  --button-size-m-lineheight: 32px;
  --button-size-m-text-transform: initial;

  // Tablet
  --button-tablet-padding: 5px 22px;
  --button-tablet-radius: 6px;
  --button-tablet-font: normal normal bold 18px/32px PT Sans;
  --button-tablet-lineheight: 32px;
  --button-tablet-text-transform: initial;

  //Mobile
  --button-mobile-padding: 9px 22px;
  --button-mobile-radius: 6px;
  --button-mobile-font: normal normal bold 16px/20px PT Sans;
  --button-mobile-lineheight: 20px;
  --button-mobile-text-transform: initial;

  // MODAL
  --modal-background-color: #fafafa;
  --modal-background-opacity: 0.8;
  --modal-header-font: normal normal bold 36px/38px Bebas Neue;
  --modal-padding: 48px;
  --modal-subhead-font: normal normal bold 24px/28px PT Sans;
  --modal-body-font: normal normal normal 18px/26px PT Sans;
  --modal-body-letter-space: 0px;
  --modal-footer-margin-top: 16px; // + 16 from inner buttons = 32px
  --modal-footer-margin-top-mobile: 0.5rem;
  --modal-footer-button-margin-top: 1rem;
  --modal-footer-button-margin-top-mobile: 0.75rem;
  --modal-boxshadow: 0px 4px 24px #65aae04d;

  --modal-header-font-tablet: normal normal bold 32px/38px Bebas Neue;
  --modal-header-font-phone: normal normal bold 24px/28px Bebas Neue;

  // TEXTAREA
  --textarea-font: normal normal normal 18px/24px PT Sans;
  --textarea-color-placeholder: #8a8a8a;
  --textarea-letter-spacing: 0;
  --textarea-border-color: #65aae0;
  --textarea-border-radius: 4px;
  --textarea-border-color-focus: var(--textarea-border-color);
  --textarea-border-width-focus: 1px;
  --textarea-padding: 16px;
  --textarea-default-height: 160px;
  --textarea-resize: none;
  --textarea-label-font: normal normal normal 18px/24px PT Sans;
  --textarea-label-letter-spacing: 0;
  --textarea-label-margin: 0 0 8px 0;
  --textarea-hint-font: normal normal normal 14px/23px PT Sans;
  --textarea-hint-color: #797a80;
  --textarea-hint-margin: 0 0 0 16px;
  --textarea-hint-letter-spacing: 0;

  // INPUT
  --input-color-placeholder: #8a8a8a;
  --input-border: 1px solid var(--color-primary);
  --input-border-radius: 4px;
  --input-padding: 15px 0 15px 16px;
  --input-labeled-padding: 24px 0 8px 16px;
  --input-font: normal normal normal 18px/24px PT Sans;
  --input-label-font: normal normal normal 14px PT Sans;
  --input-label-position: absolute;
  --input-label-position-top: 5px;
  --input-label-position-left: 17px;
  --input-height: 56px;
  --input-tablet-height: 56px;
  --input-mobile-height: 56px;
  --input-letter-spacing: 0;
  // CHECKBOX
  --checkbox-border: 1px solid #b3b3b3;
  --checkbox-border-radius: 3px;

  // FILESELECTOR
  --fileselector-border: 0px;
  --fileselector-border-radius: 6px;
  --fileselector-button-font: normal normal bold 18px/24px PT Sans;
  --fileselector-button-texttrans: initial;
  --fileselector-button-letter-space: 0px;
  --fileselector-hover-background: unset;
  --fileselector-active-background: unset;
  --fileselector-padding: 0;
  --fileselector-hint-font: 14px/20px PT Sans;
  // medium
  --fileselector-m-padding: 0;
  --fileselector-m-border-radius: 6px;
  --fileselector-m-button-font: normal normal bold 18px/24px PT Sans;
  --fileselector-m-button-texttrans: initial;
  --fileselector-inline-error-font: normal normal normal 14px/28px PT Sans;
  --fileselector-inline-error-color: #ff6677;

  //SELECT
  --select-background-color: white;
  --select-border: 1px solid var(--color-primary);
  --select-border-radius: 4px;
  --select-list-border: none;
  --select-list-box-shadow: 0 2px 8px #65aae04d;
  --select-font: normal normal normal 18px/24px PT Sans;
  --select-placeholder-letter-spacing: 0;
  --select-item-font-mobile: normal normal normal 16px/20px PT Sans;
  --select-item-padding-mobile: 8px 16px;
  --select-item-font-tablet: normal normal normal 16px/19px PT Sans;
  --select-item-padding-tablet: 8px 16px;
  --select-item-color-active: var(--color-secondary-l7);
  --select-item-background-active: var(--base-blue);
  --select-padding: 0 48px 0 16px;
  --select-arrow-color-active: #65aae0;
  --select-arrow-color: #888888;
  --select-placeholder-font: normal normal normal 18px/21px PT Sans;
  --select-inner-label-font: normal normal normal 14px/17px PT Sans;
  --select-placeholder-color: #757679;
  --select-label-font: normal normal normal 16px/28px PT Sans;
  --select-arrow-size: 16px;
  --select-arrow-right: 18px;
  --select-arrow-margin: 0;
  --select-label-display: none;
  --select-inner-label-display: block;

  //EDITOR
  --editor-font: normal normal normal 18px/24px PT Sans;
  --editor-toolbar-border-bottom: 1px solid var(--base-gray);
  --editor-padding: 16px 0 16px 16px;
  --editor-default-height: auto;
  --editor-wrapper-border: 1px solid var(--color-primary);
  --editor-wrapper-border-radius: 4px;
  --editor-wrapper-margin: 16px 0 0;
  --editor-background: white;
  --editor-desktop-image-max-width: 896px;
  --editor-tablet-image-max-width: 720px;
  --editor-phone-image-max-width: 288px;

  // Simple text content
  --text-font: normal normal normal 20px/32px PT Sans;
  --text-tablet-font: normal normal normal 18px/24px PT Sans;
  --text-mobile-font: normal normal normal 16px/20px PT Sans;

  /* =======ПЕРЕМЕННЫЕ ДЛЯ UI-KIT======= */
  --base-text: var(--color-secondary);
  --base-label-text: #7e7e7e;
  --base-border: #dedede;
  --base-blue: var(--color-primary);
  --base-bright-blue: #00aeef;
  --base-purple-ui: #8a6fd8;
  --base-red-ui: #cc2222;
  --base-yellow-ui: #ffb867;

  @include phone() {
    --modal-footer-margin-top: 0.25rem;
  }
}
