@import "../../../common";

.wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - var(--container-padding) * 2);
  background-color: #f5f6f7;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-shadow: 0px 8px 20px #0000001a;
  max-width: calc(var(--container-width) - var(--container-padding) * 2);
  padding: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  border-radius: 4px;

  @include tablet() {
    align-items: flex-start;
    gap: 24px;
    bottom: 24px;
    padding: 24px;
  }

  @include phone() {
    bottom: 16px;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 16px * 2);
  }
}

.text {
  letter-spacing: 0;
  font-size: 20px;
  line-height: 32px;

  @include tablet() {
    font-size: 18px;
    line-height: 24px;
  }

  @include phone() {
    font-size: 16px;
    line-height: 20px;
  }
}

.link {
  color: var(--color-primary);
}

.button {
  border-radius: 6px;
  font-size: 18px;
  line-height: 21px;
  padding: 11px 23px;
  font-family: "PT Sans";
  text-transform: initial;

  @include phone() {
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 22px;
  }
}
